import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Container, Typography } from '@material-ui/core';
 
const containerStyle = {
  width: '100%',
  height:'430px',
  margin:'auto'
};
 
function MyComponent() {
  const [map, setMap] = React.useState(null)
 
  const onLoad = React.useCallback(function callback(map) {
    const center = new window.google.maps.LatLng(13.132750, 79.936101);
    map.panTo(center)
    new window.google.maps.Marker({
        position: center,
        map,
        title: "Storage",
      });
    setMap(map)
  }, [])
 
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
 
  return (
    <div>
        <LoadScript
        googleMapsApiKey="AIzaSyA-eNUiDFNvZ0DxTvQ06CyfCGnXlIhjEys"
        >
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <>

            </>
        </GoogleMap>
        </LoadScript>
    </div>
  )
}
 
export default React.memo(MyComponent)