import React, { useState } from 'react'
import { AppBar, Button, IconButton, List, ListItem, ListItemText, SwipeableDrawer, Toolbar, useMediaQuery, useTheme } from "@material-ui/core";
import LOGO from "./img/logo.svg";
import LOGO_BLACK from "./img/logo-black.svg";
import { Menu } from '@material-ui/icons';
import { Link } from "react-router-dom";
const buttonStyle = {
    color:'#fff',
    fontFamily: "'Fanwood Text', serif",
    textTransform:'none',
    fontSize:"17px",
    fontWeight:"500",
    padding:'10px 20px'
}
export default function Navbar() {
    const mediaQ = useMediaQuery(useTheme().breakpoints.up('md'))
    const [drawer, setDrawer] = useState(false)
    return (
        <div>
            <AppBar 
            color='default'
            elevation= {0}
            style={{
                // background:'linear-gradient(180deg, #515151 0%, #232323 100%)',
                // color:'white',
                // background: "white",
                background: "linear-gradient(90deg, #B79891 0%, #94716B 100%)",
                fontFamily: "'Fanwood Text', serif",
                
            }} 
            >
                <Toolbar style={{maxWidth:1240,width:'100%',margin:'auto'}} >
                <div 
               >
                   <Link to='/'>
                    <img src={LOGO} style={{height:50,marginTop:6}} />
                   </Link>
                </div>
                <div style={{flexGrow:1,}}/>
               {mediaQ ? 
               <>
                   <div >
                        <Link to='/'>
                            <Button style={buttonStyle}>
                            Home
                            </Button>
                        </Link>
                        <Link to='/products'>
                            <Button style={buttonStyle}>
                            Products
                            </Button>
                        </Link>
                        <Link to='/contact'>
                            <Button style={buttonStyle}>
                            Contact
                            </Button>
                        </Link>
                </div>
                </>
                :
                    <IconButton style={{marginRight:35}} onClick={()=>setDrawer(true)} >
                        <Menu style={{color:'#efefef'}} />
                    </IconButton>
                }
                </Toolbar>
            </AppBar>
            <Toolbar />
            <SwipeableDrawer anchor={'right'} open={drawer} onClose={()=>setDrawer(false)}>
                <div style={{width:200,paddingLeft:25}}>
                    <img src={LOGO_BLACK} style={{width:'calc(100% - 25px)',display:'block',margin:'10px 0px'}} alt="orson-logo"/>
                    <List>
                        <Link to='/'>
                            <ListItem onClick={()=>setDrawer(false)} button >
                                <ListItemText primary='Home' />
                            </ListItem>
                        </Link>
                        <Link to='/products'>
                            <ListItem onClick={()=>setDrawer(false)} button >
                                <ListItemText primary='Products' />
                            </ListItem>
                        </Link>
                        <Link to='/contact'>
                            <ListItem onClick={()=>setDrawer(false)} button >
                                <ListItemText primary='Contact' />
                            </ListItem>
                        </Link>
                    </List>
                </div>
            </SwipeableDrawer>
        </div>
    )
}
