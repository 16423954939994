import { Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react'
import Carousal from "./carousal";
export default function Home() {
    const mediaQ = useMediaQuery(useTheme().breakpoints.up('md'))
    return (
        <div >
            <Carousal />
            
            <Grid style={{width:mediaQ?'100%':'95%',margin:'auto'}} container spacing={3} justify='center' >
                <Grid style={{marginTop:40}} item xs={12} md={8}>
                    <Typography style={{fontFamily:"'Fanwood Text', serif", color:"#fff", fontWeight:"800"}} align={mediaQ?'left':'center'} variant='h5'>
                        About Us
                    </Typography>
                    <hr style={{border:"1px solid white", backgroundColor:"white"}}/>
                    <br/>
                    <Typography variant='body1' align='justify' style={{color:"#fff", fontWeight:500}} >
                    ORSON INDIA is established by dynamic group of professionals with objects to lead the
                    metal market in India. Now, we are India’s upcoming aluminum ingot manufacturer, exporter
                    and one of the fastest growing private sector companies in India. Our primary business is
                    Aluminum ingot. Our vision is creating world-class metals in non ferrous metal segment in the
                    global. ORSON INDIA carrying out its activities in a socially and environmentally responsible
                    manner and creating value for the communities from it operates.
                    </Typography>
                </Grid>
                {/* <Grid style={{marginTop:40}} item xs={12} md={6}>
                    <Typography style={{fontFamily:"'Fanwood Text', serif"}} align={mediaQ?'left':'center'} variant='h5'>
                        Products
                    </Typography>
                    <hr/>
                    <br/>

                    <Typography variant='body2' align='justify'>
                    We have large capacity to automated system of manufacturing aluminum alloy ingot in user
                    friendly manner. Our Company is fully equipped with all modern machineries along with testing
                    facilities as per National and International standards and customer required specifications.
                    We provide to customer for their specified specifications of product at their required sized. Our
                    standard aluminum ingot specifications
                    </Typography>
                </Grid> */}
            </Grid>
        </div>
    )
}
