import {  Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { GetAppOutlined } from '@material-ui/icons'
import React from 'react'
import Carousal from './carousal'
// import PDF1 from "./img/ALLOY_INGOT_Specification.pdf";
import PDF2 from "./img/INGOT-Specification.pdf";
import PDF3 from "./img/ISO-Certification.pdf";




export default function Products() {
    const mediaQ = useMediaQuery(useTheme().breakpoints.up('md'))
    return (
        <div>
            <Carousal/>
            <Grid style={{width:mediaQ?'100%':'95%',margin:'auto'}} container spacing={3}>
               
                <Grid style={{marginTop:40}} item xs={12} md={6}>
                    <Typography style={{fontFamily:"'Fanwood Text', serif"}} align={mediaQ?'left':'center'} variant='h5'>
                        Aluminum Ingots
                    </Typography>
                    <hr style={{border:"1px solid white", backgroundColor:"white"}}/>
                    <br/>

                    <Typography variant='body1' align='justify' style={{color:"#fff"}}  >
                    We have large capacity to automated system of manufacturing aluminum alloy ingot in user
                    friendly manner. Our Company is fully equipped with all modern machineries along with testing
                    facilities as per National and International standards and customer required specifications.
                    We provide to customer for their specified specifications of product at their required sized. Our
                    standard aluminum ingot specifications
                    </Typography>
                </Grid>
                <Grid style={{marginTop:40}} item xs={12} md={6}>
                    <Typography style={{fontFamily:"'Fanwood Text', serif"}} align={mediaQ?'left':'center'} variant='h5'>
                        Download catalogue
                    </Typography>
                    <hr style={{border:"1px solid white", backgroundColor:"white"}}/>
                    <br/>
                        <Typography variant='overline' style={{display:'flex',justifyContent:'space-between',margin:'0px 20px'}} >
                            Aluminium Ingot specifications
                            <a href={PDF2} download>
                                <GetAppOutlined />
                            </a>
                        </Typography>
                        /* <Typography variant='overline' style={{display:'flex',justifyContent:'space-between',margin:20}} >
                            ISO Certification
                            <a href={PDF3} download>    
                            <GetAppOutlined />
                            </a>
                        </Typography> */
                </Grid>
            </Grid>
        </div>
    )
}
