import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import Map from './map'
export default function Contact() {
    return (
        <div style={{marginTop:50}}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} >
                    <Typography style={{fontFamily:"'Fanwood Text', serif"}} align='left' variant='h5'>
                    Contact us in Business Hours
                    </Typography>
                    <hr style={{border:"1px solid white", backgroundColor:"white"}}/>
                    <Typography align='left' variant='body1' style={{width:'75%',minWidth:350}}>
                       <b>
                        Address: 
                        </b> &nbsp;
                    Registered Office &amp; plant :
                    <br/>
                    &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;No.1,
                    SIDCO Electrical Industrial Estate,
                    <br/>
                    &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;Orson Plant, Unit 1
                    Kakkalur,
                    <br/>
                    &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;Tiruvallur – 602003
                    </Typography>
                <br/>
                    <Typography align='left' variant='body1'>
                    <b>
                        Phone :
                    </b>
                    &ensp;&ensp;
                        +91 8939375001
                    </Typography>
                <br/>

                    <Typography align='left' variant='body1'>
                    <b>Email :</b> &ensp;&ensp;&ensp;info@orsonindia.co.in
                    </Typography>
                    <Typography align='left' variant='body1'>
                    &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;orsonindia@hotmail.com
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Map />
  
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d485.69078770415905!2d79.9350451955011!3d13.129167684574941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a528fc432819427%3A0x553f5de758a5ea69!2sFreezeIndia%20Manufacturing%20Private%20Limited!5e0!3m2!1sen!2sin!4v1609204017953!5m2!1sen!2sin" width="100%" height='400' frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.1502442297191!2d79.93597024190754!3d13.13275185346354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDA3JzU3LjYiTiA3OcKwNTYnMTAuMiJF!5e1!3m2!1sen!2sin!4v1609594550521!5m2!1sen!2sin" width="100%" height='400' frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                </Grid>
            </Grid>
        </div>
    )
}
