import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import IMG1 from "./img/1.jpg";
import IMG2 from "./img/2.jpg";
import IMG3 from "./img/3.jpg";
const responsive = {
    desktop: {
      breakpoint: { max: 2100, min: 0 },
      items: 1,
    //   partialVisibilityGutter: 40 
    },
  };
export default function Carousal() {
  const mediaQ = useMediaQuery(useTheme().breakpoints.up('md'))
    return (
        <div>
            <Carousel 
            swipeable={false}
            draggable={false}
            centerMode={mediaQ?true:false}
            // arrows={false}
            // showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            // partialVisible={true}
            >
                {[IMG1,IMG2,IMG3].map(item=>(
                    <div style={{padding:10, borderRadius:"10px"}} >
                        <img src={item} style={{width:'100%',maxHeight:'40%', borderRadius:"10px"}} alt="carousel-image"/>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}
