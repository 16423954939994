import { Container } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import Contact from './contact';
import Home from './home';
import Navbar from "./navbar";
import Products from './products';

function App() {
  return (
    <div className='black-bg' >
      <Container maxWidth='lg'>
        <BrowserRouter>
        <Navbar />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/products' component={Products} />
            <Route path='/contact' component={Contact} />
          </Switch>
        </BrowserRouter>
      </Container>
    </div>
  );
}

export default App;
